*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

body {
  color: var(--black);
}

main:focus {
  outline: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p{
  margin: 0;
}

select{
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

ul{
  padding: 0;
  margin: 0;
}

.services-container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto 105px;
  padding: 0 10px;
}

@media(max-width:767px) {
  .services-container {
    margin: 0 auto 135px;
  }
}
