.Service {
  padding: 15px 31px 15px 15px;
  border-radius: 10px;
  align-items: center;
}

.Top{
  display: grid;
  grid-template-columns: 1fr 116px;
  justify-content: space-between;
  gap: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  align-items: flex-start;
}

.Bottom{
  font-size: 11px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Bottom p{
  padding: 10px 0;
}

.Info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px 6px;
  font-size: 12px;
}

.Name {
  display: flex;
  gap: 2px;
}
.Name > h4{
  font-weight: 600;
}

.Text {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.Text svg {
  width: 24px;
  height: 24px;
  object-fit: contain;
  flex-shrink: 0;
}

.Text > div,
.Text > span {
  display: block;
  margin-top: 4px;
}

.Btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  width: 116px;
  flex-shrink: 0;
}

.Btns > * {
  width: 100%;
}

.Stars{
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}
.Stars svg{
  fill: #ccc;
}
.Address {
  font-size: 12px;
}

.Website {
  overflow-wrap: anywhere;
}

.ContactLink {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .Top {
    grid-template-columns: 1fr;
  }

  .Btns {
    width: 100%;
    flex-direction: row;
  }
}

@media(max-width:575px){
  .Info {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 450px){
  .Btns {
    flex-direction: column;
  }
}
