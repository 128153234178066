.filters{
    padding: 28px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 10px;
    margin-bottom: 13px;
}
.selects{
    display: grid;
    grid-template-columns: 246px 287px 390px;
    gap: 8px;
}
.selects > select{
    padding: 16px 50px 16px 16px;
    border-radius: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    background-image: url(https://cdn.icon-icons.com/icons2/1904/PNG/512/downarrow_121316.png);
    background-size: 0.85em auto;
    background-repeat: no-repeat;
    background-position: calc(100% - 22px) center;
}
@media(max-width:1023px){
    .selects{
        grid-template-columns: 195px 232px;
    }
    .selectThree{
        width: 321px;
    }
}
@media(max-width:767px){
    .filters{
        padding: 18px 10px 10px;
    }
    .selects > select{
        background-position: calc(100% - 12px) center;
        padding: 8px 30px 8px 8px;
        font-size: 14px;
    }
}
@media(max-width:575px) {
    .selects{
        grid-template-columns: 1fr;
    }
    .selectOne{
        width: 195px;
    }
    .selectTwo{
        width: 232px;
    }
    .selectThree{
        max-width: 300px;
    }
}