.List {
  display: grid;
  grid-template-columns: 100%;
  list-style: none;
  padding-right: 15px;
  max-height: 377px;
  overflow-y: scroll;
}

::-webkit-scrollbar{
  height: 12px;
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background-color: var(--orange);
  border-radius: 5px;
}
