.sectionTitle {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 20px;
}

.servicesBoxes {
  width: 100%;
  display: grid;
  align-items: flex-start;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

@media(max-width:900px) {
  .servicesBoxes {
    gap: 32px;
    grid-template-columns: 1fr;
  }
}
