@font-face {
  font-family: 'Montserrat';
  src: url('MontserratThin-Regular.eot');
  src: url('MontserratThin-Regular.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-Regular.woff2') format('woff2'),
    url('MontserratThin-Regular.woff') format('woff'),
    url('MontserratThin-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('MontserratThin-Regular_1.eot');
  src: url('MontserratThin-Regular_1.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-Regular_1.woff2') format('woff2'),
    url('MontserratThin-Regular_1.woff') format('woff'),
    url('MontserratThin-Regular_1.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url('MontserratThin-Medium.eot');
  src: url('MontserratThin-Medium.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-Medium.woff2') format('woff2'),
    url('MontserratThin-Medium.woff') format('woff'),
    url('MontserratThin-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url('MontserratThin-Medium_1.eot');
  src: url('MontserratThin-Medium_1.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-Medium_1.woff2') format('woff2'),
    url('MontserratThin-Medium_1.woff') format('woff'),
    url('MontserratThin-Medium_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url('MontserratThin-SemiBold.eot');
  src: url('MontserratThin-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-SemiBold.woff2') format('woff2'),
    url('MontserratThin-SemiBold.woff') format('woff'),
    url('MontserratThin-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Thin';
  src: url('MontserratThin-SemiBold_1.eot');
  src: url('MontserratThin-SemiBold_1.eot?#iefix') format('embedded-opentype'),
    url('MontserratThin-SemiBold_1.woff2') format('woff2'),
    url('MontserratThin-SemiBold_1.woff') format('woff'),
    url('MontserratThin-SemiBold_1.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
