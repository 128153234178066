.gap-15 {
  gap: 15px;
}

.center{
  text-align: center;
  margin: 0 auto;
}

.upper-case{
  text-transform: uppercase;
}

.btn{
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  padding: 0;
  cursor: pointer;
}
