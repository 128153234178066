.orange{
    color: #f4511e;
}

.heroSection {
    width: 100%;
    padding-bottom: 39px;
    padding-top: 50px;
}

.heroContainer {
    width: 100%;
    margin: 0 auto;
    max-width: 1220px;
}

.heroText{
    line-height: 1.4;
}

.header{
    margin-top: 30px;
    margin-bottom: 10px;
    max-width: 1140px;
    padding: 0 15px;
}

.main {
    max-width: 1140px;
    padding: 0 15px;
}

.line{
    width: 70px;
    height: 10px;
    margin: 0 auto;
    margin-bottom: 34px;
}

@media(max-width: 1199px){
    .header,
    .main {
        max-width: 990px;
    }
}

@media(max-width:1023px){
    .heroText {
        /* max-width: 690px; */
    }

    .header,
    .main {
        max-width: 750px;
    }
}
@media(max-width:767px) {
    .heroText{
        /* max-width: 510px; */
        margin-bottom: 20px;
    }

    .header,
    .main {
        max-width: 540px;
    }
}
@media(max-width:575px){
    .heroText{
        max-width: 100%;
        /* padding: 0 15px; */
    }

    .header,
    .main {
        max-width: 100%;
    }
}