:root {
  --orange: #FF4B00;
  --gray-dark: hsla(0, 0%, 29%, 1);
  --gray: #F5F5F5;
  --light-gray: #fafafa;
  --midle-gray: #757575;
  --black: #000;
  --white: #fff;
  --active-gray: #dfdddd;
}

.bg-orange {
  background-color: var(--orange);
}

.bg-gray {
  background-color: var(--gray);
}

.bg-active-gray {
  background-color: var(--active-gray);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.color-orange {
  color: var(--orange);
}

.color-gray {
  color: var(--gray);
}

.color-gray-dark{
  color: var(--gray-dark);
}
.color-midle-gray{
  color: var(--midle-gray)
}

.font-30 {
  font-size: 30px;
}

.h2 {
  font-size: 33px;
}

.h3 {
  font-size: 30px;
}

.h5{
  font-size: 15px;
  letter-spacing: .042rem;
}

.bg-white{
  background-color: var(--white);
}

.color-white{
  color: var(--white);
}

.h6{
  font-weight: 600;
  font-size: 12px;
}

@media(max-width:767px) {
  .h3{
    font-size: 20px;
  }
}